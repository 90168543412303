import React from 'react';

const BACKEND_URL = 'https://gathr-backend-951249927221.northamerica-northeast1.run.app';

const ICONS = {
  event: {
    grey: `${BACKEND_URL}/event-grey.png`,
    yellow: `${BACKEND_URL}/event-yellow.png`,
    green: `${BACKEND_URL}/event-green.png`,
  },
  special: {
    grey: `${BACKEND_URL}/special-grey.png`,
    yellow: `${BACKEND_URL}/special-yellow.png`,
    green: `${BACKEND_URL}/special-green.png`,
  },
};

const CustomMarker = React.memo(({ type, status, count }) => {
  const iconUrl = ICONS[type]?.[status];
  
  if (!iconUrl) {
    console.warn(`No icon found for type: ${type}, status: ${status}`);
    return (
      <div style={{ 
        width: '30px', 
        height: '30px', 
        backgroundColor: status === 'green' ? 'green' : status === 'yellow' ? 'yellow' : 'grey',
        color: 'white', 
        textAlign: 'center', 
        lineHeight: '30px',
        borderRadius: '50%',
        fontSize: '12px'
      }}>
        {type === 'event' ? 'E' : type === 'special' ? 'S' : 'U'}
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <img 
        src={iconUrl} 
        alt={`${type} ${status}`} 
        style={{ width: '30px', height: '30px' }} 
      />
      {count && count > 1 && (
        <div style={{
          position: 'absolute',
          top: -5,
          right: -5,
          backgroundColor: 'red',
          color: 'white',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '12px',
          fontWeight: 'bold'
        }}>
          {count}
        </div>
      )}
    </div>
  );
});

export default CustomMarker;