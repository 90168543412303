import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import BlurredPanel from './BlurredPanel';
import ToggleSwitch from './ToggleSwitch';
import FilterSelect from './FilterSelect';

const CenterButton = styled(Button)(({ theme, $isclear }) => ({
  backgroundColor: '#E9EBEC',
  color: '#8B0000',
  border: '2px solid #4f4f4f',
  borderRadius: '8px',
  fontSize: '0.7rem',
  padding: '4px 8px',
  minWidth: '50px',
  '&:hover': {
    backgroundColor: 'rgba(213, 213, 213, 2.7)',
  },
  ...($isclear && {
    backgroundColor: '#dc3545',
    color: 'white',
    '&:hover': {
      backgroundColor: '#c82333',
    },
  }),
}));

const FilterContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '6px',
  gap: '6px',
});

const SectionHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '4px',
});

const HeaderLabel = styled(Typography)({
  fontSize: '14px',
  fontWeight: '600',
  color: '#333',
  textAlign: 'center',
  marginBottom: '4px',
});

const FilterPanel = ({
  eventsSelected,
  setEventsSelected,
  eventsTimeFilter,
  setEventsTimeFilter,
  eventsCategoryFilter,
  setEventsCategoryFilter,
  eventsCategories,
  specialsSelected,
  setSpecialsSelected,
  specialsTimeFilter,
  setSpecialsTimeFilter,
  specialsCategoryFilter,
  setSpecialsCategoryFilter,
  specialsCategories,
  isVisible,
  onClose,
  onClear,
}) => {
  const leftPanelRef = useRef(null);
  const rightPanelRef = useRef(null);
  const centerButtonRef = useRef(null);

  // Check for active filters
  const hasActiveFilters = 
    !eventsSelected ||
    !specialsSelected ||
    eventsTimeFilter !== '' ||
    specialsTimeFilter !== '' ||
    eventsCategoryFilter !== '' ||
    specialsCategoryFilter !== '';

  // Button text management
  const getButtonText = () => {
    // When panels are closed, always show "Filters"
    if (!isVisible) return 'Filters';
    // When panels are open and filters are active, show "Clear"
    if (hasActiveFilters) return 'Clear';
    // When panels are open but no filters active, show "Close"
    return 'Close';
  };

  const timeOptions = [
    //{ value: '', label: 'All' },
    { value: 'Now', label: 'Now' },
    { value: 'Today', label: 'Today' },
    { value: 'Upcoming', label: 'Upcoming' }
  ];

  const formatCategoryOptions = (categories) => [
    //{ value: '', label: 'All' },
    ...categories.map(category => ({
      value: category,
      label: category
    }))
  ];

  // Click away handler
  useEffect(() => {
    const handleClickAway = (event) => {
      if (!isVisible) return;
      
      const isClickInside = 
        leftPanelRef.current?.contains(event.target) ||
        rightPanelRef.current?.contains(event.target) ||
        centerButtonRef.current?.contains(event.target) ||
        event.target.closest('.MuiMenu-paper') ||
        event.target.closest('.MuiBackdrop-root');

      if (!isClickInside) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickAway);
    document.addEventListener('touchstart', handleClickAway);

    return () => {
      document.removeEventListener('mousedown', handleClickAway);
      document.removeEventListener('touchstart', handleClickAway);
    };
  }, [isVisible, onClose]);

  const handleEventsToggle = (newValue) => {
    setEventsSelected(newValue);
  };

  const handleSpecialsToggle = (newValue) => {
    setSpecialsSelected(newValue);
  };

  // Handle button clicks based on current state
  const handleButtonClick = () => {
    if (!isVisible) {
      // If panels are closed, open them
      onClose(); // This actually opens the panels
    } else if (hasActiveFilters) {
      // If filters are active, just clear them but keep panels open
      onClear();
      // No need to close panels or change visibility
    } else {
      // If no filters are active and panels are open, close panels
      onClose();
    }
  };

  return (
    <>
      <CenterButton
        ref={centerButtonRef}
        onClick={handleButtonClick}
        $isclear={isVisible && hasActiveFilters}
      >
        {getButtonText()}
      </CenterButton>

      <AnimatePresence>
        {isVisible && (
          <>
            <BlurredPanel
              side="left"
              isVisible={isVisible}
              width="30%"
              ref={leftPanelRef}
            >
              <FilterContainer>
                <SectionHeader>
                  <HeaderLabel>Events</HeaderLabel>
                  <ToggleSwitch
                    isOn={eventsSelected}
                    onToggle={handleEventsToggle}
                    color="#2196f3"
                  />
                </SectionHeader>
                
                <FilterSelect
                  label="Time"
                  value={eventsTimeFilter}
                  onChange={(e) => setEventsTimeFilter(e.target.value)}
                  options={timeOptions}
                  disabled={!eventsSelected}
                  placeholder="All"
                />

                <FilterSelect
                  label="Category"
                  value={eventsCategoryFilter}
                  onChange={(e) => setEventsCategoryFilter(e.target.value)}
                  options={formatCategoryOptions(eventsCategories)}
                  disabled={!eventsSelected}
                  placeholder="All"
                />
              </FilterContainer>
            </BlurredPanel>

            <BlurredPanel
              side="right"
              isVisible={isVisible}
              width="30%"
              ref={rightPanelRef}
            >
              <FilterContainer>
                <SectionHeader>
                  <HeaderLabel>Specials</HeaderLabel>
                  <ToggleSwitch
                    isOn={specialsSelected}
                    onToggle={handleSpecialsToggle}
                    color="#2196f3"
                  />
                </SectionHeader>
                
                <FilterSelect
                  label="Time"
                  value={specialsTimeFilter}
                  onChange={(e) => setSpecialsTimeFilter(e.target.value)}
                  options={timeOptions}
                  disabled={!specialsSelected}
                  placeholder="All"
                />

                <FilterSelect
                  label="Category"
                  value={specialsCategoryFilter}
                  onChange={(e) => setSpecialsCategoryFilter(e.target.value)}
                  options={formatCategoryOptions(specialsCategories)}
                  disabled={!specialsSelected}
                  placeholder="All"
                />
              </FilterContainer>
            </BlurredPanel>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default FilterPanel;