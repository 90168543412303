import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';

// Main panel component with blur effect
const BlurredBackground = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  // Background and blur settings
  backgroundColor: 'rgba(255, 255, 255, 0.85)', // Adjust opacity here (0-1)
  backdropFilter: 'blur(15px)', // Adjust blur intensity here
  WebkitBackdropFilter: 'blur(15px)', // Safari support - keep same as above
  
  // Visual styling
  borderRadius: '12px', // Adjust corner rounding
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.1)', // Layered shadow for depth
  overflow: 'hidden',
  zIndex: 1000,
}));

// Animation variants
const panelVariants = {
  hidden: (side) => ({
    x: side === 'left' ? '-100%' : '100%',
    opacity: 0
  }),
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 300, // Adjust animation spring stiffness
      damping: 30    // Adjust animation damping
    }
  },
  exit: (side) => ({
    x: side === 'left' ? '-100%' : '100%',
    opacity: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 30
    }
  })
};

const BlurredPanel = forwardRef(({
  children,
  side = 'left',
  width = '30%', // Increased from 25% to 30%
  top = '64px',  // Matches AppBar height
  isVisible = false,
  style = {},
  ...props
}, ref) => {
  const position = {
    top,
    [side]: '2px', // Reduced from 5px to 2px - minimal edge padding
  };

  return (
    <BlurredBackground
      ref={ref}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      exit="exit"
      variants={panelVariants}
      custom={side}
      style={{
        width,
        ...position,
        ...style,
      }}
      {...props}
    >
      {children}
    </BlurredBackground>
  );
});

export default BlurredPanel;