// ListView.js

import React, { useState, useMemo, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Chip, 
  IconButton,
  Paper,
  styled,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import moment from 'moment';

// Styled components for consistent styling
const EventCard = styled(Paper)(({ theme }) => ({
  maxWidth: '600px',
  margin: '0 auto',
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  maxWidth: '600px',
  margin: '0 auto',
  marginBottom: theme.spacing(3),
}));

const EventImage = styled('img')({
  width: '100%',
  height: '200px',
  objectFit: 'cover',
});

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const isValidTicketLink = (link) => {
  return link && link !== '' && link.toLowerCase() !== 'n/a';
};

// Function to handle "Get Tickets" link
const handleGetTickets = (ticketLinkPosts, ticketLinkEvents) => {
  const ticketLink = isValidTicketLink(ticketLinkPosts) 
    ? ticketLinkPosts 
    : ticketLinkEvents;
    
  if (isValidTicketLink(ticketLink)) {
    window.open(ticketLink, '_blank');
  }
};

const ChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const AdComponent = ({ isDisabled = false }) => {
  const [adError, setAdError] = useState(null);
  const [showFallback, setShowFallback] = useState(false);
  const adRef = useRef(null);
  const initialized = useRef(false);
  const attemptedInitialization = useRef(false);

  useEffect(() => {
    if (isDisabled || initialized.current || attemptedInitialization.current) {
      return;
    }

    console.log("AdComponent mounted");
    attemptedInitialization.current = true;

    const loadAdScript = () => {
      return new Promise((resolve, reject) => {
        if (window.adsbygoogle) {
          console.log("AdSense script already loaded");
          resolve();
          return;
        }

        const adScript = document.createElement("script");
        adScript.async = true;
        adScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9606287073864764";
        adScript.crossOrigin = "anonymous";
        adScript.onload = () => {
          console.log("AdSense script loaded successfully");
          resolve();
        };
        adScript.onerror = (error) => {
          console.error("AdSense script failed to load:", error);
          reject(error);
        };
        document.head.appendChild(adScript);
      });
    };

    const initializeAd = async () => {
      try {
        await loadAdScript();

        if (typeof window.adsbygoogle === 'undefined') {
          throw new Error("AdSense object not available after script load");
        }

        if (adRef.current && !adRef.current.hasAttribute('data-adsbygoogle-initialized')) {
          console.log("Attempting to initialize ad");
          window.adsbygoogle.push({});
          initialized.current = true;
          console.log("Ad push called");

          const timeout = setTimeout(() => {
            console.log("Ad load timeout, showing fallback");
            setShowFallback(true);
          }, 10000);

          const checkAdLoaded = setInterval(() => {
            if (adRef.current && adRef.current.querySelector('iframe')) {
              console.log("Ad iframe detected, clearing timeout");
              clearTimeout(timeout);
              clearInterval(checkAdLoaded);
            }
          }, 1000);

          return () => {
            clearTimeout(timeout);
            clearInterval(checkAdLoaded);
          };
        } else {
          console.log("Ad already initialized or ref not available");
        }
      } catch (e) {
        console.error('AdSense error:', e);
        setAdError(e.message);
      }
    };

    initializeAd();
  }, [isDisabled]);

  if (isDisabled) {
    return null;
  }

  return (
    <div style={{ minHeight: '100px', margin: '10px 0' }}>
      {adError ? (
        <div style={{ color: 'red', textAlign: 'center' }}>
          Ad failed to load: {adError}
        </div>
      ) : showFallback ? (
        <img 
          src="https://via.placeholder.com/300x100?text=Fallback+Ad" 
          alt="Fallback Ad" 
          style={{ width: '100%', height: '100px', objectFit: 'cover' }}
        />
      ) : (
        <ins
          ref={adRef}
          className="adsbygoogle"
          style={{
            display: 'block',
            border: '1px solid #ccc',
            minHeight: '100px',
            backgroundColor: '#f0f0f0',
          }}
          data-ad-client="ca-pub-9606287073864764"
          data-ad-slot="1902171465"
          data-ad-format="fluid"
          data-ad-layout-key="-ef+6k-30-ac+ty"
          data-full-width-responsive="true"
        ></ins>
      )}
    </div>
  );
};

// ImageCarousel component (unchanged)
const ImageCarousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <Box sx={{ position: 'relative' }}>
      <EventImage 
        src={images[currentImageIndex]} 
        alt="Event" 
        onError={(e) => {
          e.target.src = `${process.env.PUBLIC_URL}/fallbackimage.webp`;
        }}
      />
    </Box>
  );
};

// Main ListView component
const ListView = ({ 
  items,
  onItemClick,
  viewType,
  timeFilter,
  setTimeFilter,
  categoryFilter,
  setCategoryFilter,
  searchQuery,
  setSearchQuery,
  userLocation, // Added userLocation prop
}) => {
  const [venueIndices, setVenueIndices] = useState({});

  // Scoring weights and proximity tiers (adjustable)
  const SCORING_WEIGHTS = {
    timeRelevance: 4,           // Weight for time relevance score
    proximity: 3,               // Weight for proximity score
    interactionCount: 1,        // Weight for interaction count score
    // userInterests: 1,        // Placeholder for future use
    // favoriteEstablishment: 1,// Placeholder for future use
  };

  const PROXIMITY_TIERS = [
    { radius: 1, score: 5 },    // Within 1 km
    { radius: 5, score: 4 },    // Within 5 km
    { radius: 10, score: 2 },   // Within 10 km
    { radius: 50, score: 1 },   // Within 50 km
    { radius: 70, score: -1 },   // Within 50 km
    { radius: 100, score: -2 },   // Within 100 km
    { radius: Infinity, score: -3 }, // Beyond 100 km
  ];

  // Helper function to get images for an event
  const getImages = (event) => {
    const images = [];
    if (event.relevantImageUrl) images.push(event.relevantImageUrl);
    if (event.imageUrl) images.push(event.imageUrl);
    if (event.SharedPostThumbnail) images.push(event.SharedPostThumbnail);
    return images;
  };

  // Helper function to determine the time status of an item
// Update the getMarkerStatus function to ensure ongoing events show "Happening Now"
const getMarkerStatus = (item) => {
  const now = moment();
  const { startDate, endDate, startTime, endTime } = item;

  const start = moment(`${startDate}T${startTime}`, [
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm',
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD H:mm:ss',
    'YYYY-MM-DD hh:mm A',
    'YYYY-MM-DD h:mm A',
  ]);
  const end = moment(`${endDate}T${endTime}`, [
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm',
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD H:mm:ss',
    'YYYY-MM-DD hh:mm A',
    'YYYY-MM-DD h:mm A',
  ]);

  if (now.isBetween(start, end)) {
    return 'green'; // Happening now
  }

  // Continue with the existing conditions
  const inAWeek = moment().add(7, 'days').endOf('day');

  if (start.isSameOrBefore(now, 'day') && end.isAfter(now, 'day')) {
    return 'yellow'; // Happening today
  }

  if (start.isAfter(now) && start.isBefore(inAWeek)) {
    return 'grey'; // Upcoming in the next 7 days
  }

  return 'expired'; // Event has ended or is more than 7 days away
};

  // Function to calculate the time relevance score
  const getTimeRelevanceScore = (item) => {
    const status = getMarkerStatus(item);
    switch (status) {
      case 'green':
        return 6; // Highest priority
      case 'yellow':
        return 5;
      case 'grey':
        return 0;
      default:
        return 0;
    }
  };

  // Haversine formula to calculate distance between two coordinates
  const haversineDistance = (coords1, coords2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Earth's radius in kilometers

    const dLat = toRad(coords2.latitude - coords1.latitude);
    const dLon = toRad(coords2.longitude - coords1.longitude);

    const lat1 = toRad(coords1.latitude);
    const lat2 = toRad(coords2.latitude);

    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in kilometers
  };

  // Function to calculate the proximity score
  const getProximityScore = (item) => {
    if (!item.latitude || !item.longitude || !userLocation) {
      return 0; // Unable to calculate distance
    }
    const eventCoords = { latitude: item.latitude, longitude: item.longitude };
    const distance = haversineDistance(eventCoords, userLocation);
    for (const tier of PROXIMITY_TIERS) {
      if (distance <= tier.radius) {
        return tier.score;
      }
    }
    return 0;
  };

  // Function to calculate the interaction count score
  const getInteractionScore = (item) => {
    const interactions = item.usersResponded ?? 0; // Default to 0 if undefined
    // Using logarithmic scale to normalize the score
    return Math.log1p(interactions); // log(1 + interactions)
  };

  // Function to compute the total score for an item
  const computeTotalScore = (item) => {
    const timeScore = getTimeRelevanceScore(item) * SCORING_WEIGHTS.timeRelevance;
    const proximityScore = getProximityScore(item) * SCORING_WEIGHTS.proximity;
    const interactionScore = getInteractionScore(item) * SCORING_WEIGHTS.interactionCount;
    // Placeholder scores for future use
    // const interestScore = 0; // No user interests data yet
    // const favoriteScore = 0; // No favorite establishments data yet

    return timeScore + proximityScore + interactionScore;
  };

  // Compute scores for all items
  const scoredItems = useMemo(() => {
    return items.map((item) => ({
      ...item,
      totalScore: computeTotalScore(item),
    }));
  }, [items, userLocation]);

  // Filter items based on search query, category, and time filter
  const filteredItems = useMemo(() => {
    return scoredItems.filter(item => {
      // Filter by search query
      const matchesSearch = !searchQuery || 
                            item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            item.venue.toLowerCase().includes(searchQuery.toLowerCase());
      
      // Filter by category
      const matchesCategory = !categoryFilter || categoryFilter === '' || item.category === categoryFilter;
      
      // Get the time status of the item
      const markerStatus = getMarkerStatus(item);
      
      // Filter by time
      let matchesTime = true;
      if (timeFilter) {
        if (timeFilter === 'Now' && markerStatus !== 'green') matchesTime = false;
        else if (timeFilter === 'Today' && !['green', 'yellow'].includes(markerStatus)) matchesTime = false;
        else if (timeFilter === 'Upcoming' && markerStatus !== 'grey') matchesTime = false;
      } else {
        // Exclude items that have expired or are too far in the future
        if (!['green', 'yellow', 'grey'].includes(markerStatus)) matchesTime = false;
      }

      return matchesSearch && matchesCategory && matchesTime;
    });
  }, [scoredItems, searchQuery, categoryFilter, timeFilter]);

  // Sort items by total score in descending order
  const sortedItems = useMemo(() => {
    return [...filteredItems].sort((a, b) => b.totalScore - a.totalScore);
  }, [filteredItems]);

  // Group events by venue
  const groupedEvents = useMemo(() => {
    const groups = sortedItems.reduce((acc, event) => {
      if (!acc[event.venue]) acc[event.venue] = [];
      acc[event.venue].push(event);
      return acc;
    }, {});

    // Sort events within each venue by total score
    for (const venue in groups) {
      groups[venue].sort((a, b) => b.totalScore - a.totalScore);
    }

    return groups;
  }, [sortedItems]);

  // Event handlers for navigating between events of the same venue
  const handlePrevEvent = (venue, e) => {
    e.stopPropagation();
    setVenueIndices(prev => {
      const venueGroup = groupedEvents[venue];
      const newIndex = (((prev[venue] || 0) - 1) + venueGroup.length) % venueGroup.length;
      return { ...prev, [venue]: newIndex };
    });
  };

  const handleNextEvent = (venue, e) => {
    e.stopPropagation();
    setVenueIndices(prev => {
      const venueGroup = groupedEvents[venue];
      const newIndex = ((prev[venue] || 0) + 1) % venueGroup.length;
      return { ...prev, [venue]: newIndex };
    });
  };

  // Helper function to format dates
  const formatDate = (startDate, startTime, endDate, endTime, status) => {
    const start = moment(`${startDate}T${startTime}`, [
      'YYYY-MM-DDTHH:mm:ss',
      'YYYY-MM-DDTHH:mm',
      'YYYY-MM-DD HH:mm:ss',
      'YYYY-MM-DD H:mm:ss',
      'YYYY-MM-DD hh:mm A',
      'YYYY-MM-DD h:mm A',
    ]);
  
    const end = moment(`${endDate}T${endTime}`, [
      'YYYY-MM-DDTHH:mm:ss',
      'YYYY-MM-DDTHH:mm',
      'YYYY-MM-DD HH:mm:ss',
      'YYYY-MM-DD H:mm:ss',
      'YYYY-MM-DD hh:mm A',
      'YYYY-MM-DD h:mm A',
    ]);
  
    if (!start.isValid() || !end.isValid()) return 'Date TBA';
  
    switch (status) {
      case 'green': // Happening Now
        return `Happening Now Until ${end.format('MMM D, YYYY [at] h:mm A')}`;
  
      case 'yellow': // Happening Today
        return `Happening Today at ${start.format('h:mm A')} Until ${end.format('MMM D, YYYY [at] h:mm A')}`;
  
      case 'grey': // Upcoming
        return `${start.format('MMM D, YYYY [at] h:mm A')} Until ${end.format('MMM D, YYYY [at] h:mm A')}`;
  
      default:
        return 'Date TBA';
    }
  };

  return (
    <Box sx={{ height: 'calc(100vh - 112px)', overflowX: 'hidden', overflowY: 'auto', paddingTop: 1}}>
      {/* Filter Container */}
      <FilterContainer>
        {/* Search Field */}
        <TextField
          fullWidth
          label={`Search ${viewType} or venues`}
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 1 }}
        />
        <Box sx={{ display: 'flex', gap: 1 }}>
          {/* Time Filter */}
          <FormControl fullWidth>
            <InputLabel>Time</InputLabel>
            <Select
              value={timeFilter || ''}
              onChange={(e) => setTimeFilter(e.target.value)}
              label="Time"
            >
              <MenuItem value="">
                <em>All Times</em>
              </MenuItem>
              <MenuItem value="Now">Happening Now</MenuItem>
              <MenuItem value="Today">Happening Today</MenuItem>
              <MenuItem value="Upcoming">Upcoming</MenuItem>
            </Select>
          </FormControl>
          {/* Category Filter */}
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={categoryFilter || ''}
              onChange={(e) => setCategoryFilter(e.target.value)}
              label="Category"
            >
              <MenuItem value="">
                <em>All Categories</em>
              </MenuItem>
              {Array.from(new Set(items.map(item => item.category).filter(Boolean))).map(category => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </FilterContainer>

      {/* Render events grouped by venue */}
      {Object.entries(groupedEvents).map(([venue, venueEvents], index) => {
        const currentIndex = venueIndices[venue] || 0;
        const currentEvent = venueEvents[currentIndex];
        const eventImages = getImages(currentEvent);

        return (
          <React.Fragment key={venue}>
            <EventCard elevation={3} onClick={() => onItemClick(currentEvent)}>
              <Typography variant="h6" align="center" sx={{ p: 1, fontWeight: 'bold' }}>{venue}</Typography>
              <Typography variant="body2" align="center" sx={{ px: 1, pb: 1, color: 'text.secondary' }}>{currentEvent.address}</Typography>
              {eventImages.length > 0 && <ImageCarousel images={eventImages} />}
              <ContentBox>
                <ChipContainer>

                  {/* Category chip */}
                  {currentEvent.category && (
                    <Chip
                      label={currentEvent.category}
                      color="primary"  // Customize color as needed
                      size="small"
                    />
                  )}
                   {/* Price chip */}
                  <Chip 
                    label={currentEvent.ticketPrice || 'Free'} 
                    color={currentEvent.ticketPrice ? 'primary' : 'success'}
                    size="small"
                  />
                    {/* Get Tickets Chip with consistent styling */}
                    {(isValidTicketLink(currentEvent.ticketLinkPosts) || isValidTicketLink(currentEvent.ticketLinkEvents)) && (
                        <Chip
                          label="Get Tickets"
                          clickable
                          color="primary"
                          onClick={() => handleGetTickets(currentEvent.ticketLinkPosts, currentEvent.ticketLinkEvents)}
                          sx={{
                            height: '24px', // Set consistent height
                            fontSize: '0.8rem', // Adjust font size if needed
                            bgcolor: '#2196f3', 
                            color: 'white', 
                            padding: '4px 8px', // Consistent padding for height alignment
                            minWidth: '70px', // Ensure width is consistent
                            fontWeight: 'Normal', // Bold text if needed for emphasis
                            '&:hover': { bgcolor: '#1976d2' }, // Consistent hover effect
                           // fontWeight: 'bold', // Bold text if needed for emphasis
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center'
                          }}
                        />
                      )}
                    </ChipContainer>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>{currentEvent.title}</Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  {formatDate(currentEvent.startDate, currentEvent.startTime, currentEvent.endDate, currentEvent.endTime, getMarkerStatus(currentEvent))}
                </Typography>
                <Typography variant="body2">{currentEvent.description}</Typography>
              </ContentBox>
              <NavigationContainer>
                <IconButton onClick={(e) => handlePrevEvent(venue, e)} disabled={venueEvents.length === 1}>
                  <ChevronLeft />
                </IconButton>
                <Typography variant="body2" color="text.secondary">
                  {`${currentIndex + 1} of ${venueEvents.length}`}
                </Typography>
                <IconButton onClick={(e) => handleNextEvent(venue, e)} disabled={venueEvents.length === 1}>
                  <ChevronRight />
                </IconButton>
              </NavigationContainer>
            </EventCard>
            
            {/* Ad component every 2 items */}
            {(index + 1) % 2 === 0 && (
              <Box sx={{ my: 2 }}>
                <AdComponent />
              </Box>
            )}
          </React.Fragment>
        );
      })}

      {/* No items found message */}
      {filteredItems.length === 0 && (
        <Typography variant="h6" sx={{ textAlign: 'center', mt: 4 }}>
          No {viewType} found matching your criteria.
        </Typography>
      )}
    </Box>
  );
};

export default ListView;
