import React from 'react';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';

const ToggleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  gap: '8px',
});

const ToggleTrack = styled(motion.div)({
  width: '32px',
  height: '16px',
  borderRadius: '16px',
  position: 'relative',
  cursor: 'pointer',
});

const ToggleHandle = styled(motion.div)({
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  backgroundColor: 'white',
  position: 'absolute',
  top: '1px',
});

const Label = styled('span')(({ theme, disabled }) => ({
  fontSize: '14px',
  fontWeight: 600,
  color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  cursor: 'pointer',
}));

const ToggleSwitch = ({
  isOn = false,
  onToggle,
  label,
  disabled = false,
  color = '#2196f3',
  disabledColor = '#ccc',
  style = {},
  labelStyle = {},
}) => {
  const handleClick = () => {
    if (!disabled && onToggle) {
      onToggle(!isOn);
    }
  };

  return (
    <ToggleContainer
      onClick={handleClick}
      style={style}
    >
      {label && (
        <Label 
          disabled={disabled}
          style={labelStyle}
        >
          {label}
        </Label>
      )}
      <ToggleTrack
        animate={{
          backgroundColor: disabled ? disabledColor : isOn ? color : '#e0e0e0',
        }}
        initial={false}
        style={{
          opacity: disabled ? 0.6 : 1,
        }}
      >
        <ToggleHandle
          animate={{
            x: isOn ? 16 : 1,
            scale: isOn ? 1.1 : 1,
            boxShadow: isOn 
              ? '0 2px 4px rgba(0,0,0,0.2)'
              : '0 1px 2px rgba(0,0,0,0.2)',
          }}
          transition={{
            type: "spring",
            stiffness: 500,
            damping: 30
          }}
        />
      </ToggleTrack>
    </ToggleContainer>
  );
};

export default ToggleSwitch;