// App.js

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  Box,
  Button,
  Select,
  MenuItem,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Alert,
  Typography,
  FormControl,
  InputLabel,
  ClickAwayListener,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import moment from 'moment';
import ListView from './ListView';
import useSupercluster from 'use-supercluster';
import ClusterCarousel from './ClusterCarousel';
import CarouselPopup from './CarouselPopup';
import CustomMarker from './CustomMarker';
import FilterPanel from './FilterPanel';
import { useMap } from 'react-map-gl';
import { calculateOptimalMapPosition, needsRecentering } from './mapUtils';


// Replace with your actual Mapbox token and backend URL
const MAPBOX_TOKEN = 'pk.eyJ1IjoiY3JhaWdidXJnb3luZSIsImEiOiJjbHllaTM2em8wMndqMm1vcWZoYmZnaG9rIn0.hHWwDWHl2wqegwpT3lIgWg';
const BACKEND_URL = 'https://gathr-backend-951249927221.northamerica-northeast1.run.app';

// Custom Specials Icon component for the bottom navigation
const SpecialsIcon = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <RestaurantIcon sx={{ fontSize: 20, mr: 0, ml: 0.4 }} />
    <SportsBarIcon sx={{ fontSize: 20, ml: 0, mr: 0.2 }} />
  </Box>
);

// Utility function to format dates
function formatDate(date) {
  return moment(date).format('MMM D');
}

// Utility function to format times
function formatTime(timeString) {
  return moment(timeString, ['h:mm A', 'H:mm:ss'])
    .format('h:mm A')
    .toLowerCase();
}

// Determines the status of a marker based on current time
function getMarkerStatus(marker) {
  const now = moment();
  const { startDate, endDate, startTime, endTime } = marker;

  const start = moment(`${startDate}T${startTime}`, [
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm',
  ]);
  const end = moment(`${endDate}T${endTime}`, [
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm',
    'YYYY-MM-DDTh:mm A',
  ]);

  const inAWeek = moment().add(7, 'days').endOf('day'); // Adjust the number of days as needed

  if (now.isBetween(start, end)) {
    return 'green'; // Happening now
  }

  if (start.isSameOrBefore(now, 'day') && end.isAfter(now, 'day')) {
    return 'yellow'; // Happening today
  }

  if (start.isAfter(now) && start.isBefore(inAWeek)) {
    return 'grey'; // Upcoming in the next 7 days
  }

  return 'expired'; // Event has ended or is more than 7 days away
}

function App() {
  // Map view state
  const [viewState, setViewState] = useState({
    latitude: 46.2352,
    longitude: -63.1262,
    zoom: 12,
  });

  const mapRef = useRef();

  // Current view state for overflow control
  const [currentView, setCurrentView] = useState('map'); // Controls body overflow behavior
  const [view, setView] = useState('map'); // Controls which view is displayed ('map', 'events', 'specials')

  // Markers and selection states
  const [markers, setMarkers] = useState([]); // All markers fetched from the backend
  const [selectedLocation, setSelectedLocation] = useState(null); // Selected marker or cluster
  const [selectedCluster, setSelectedCluster] = useState(null); // Selected cluster
  const [bounds, setBounds] = useState(null); // Map bounds for clustering
  const [isMapAlertVisible, setMapAlertVisible] = useState(true); // Visibility of the map alert

  // User location state
  const [userLocation, setUserLocation] = useState(null); // User's current location

  // ListView search query states
  const [eventsSearchQuery, setEventsSearchQuery] = useState(''); // Search query for events view
  const [specialsSearchQuery, setSpecialsSearchQuery] = useState(''); // Search query for specials view

  // New Filters
  const [filterPanelsVisible, setFilterPanelsVisible] = useState(false);

  // Filter toggle states
  const [eventsSelected, setEventsSelected] = useState(true); // Toggle for Events
  const [specialsSelected, setSpecialsSelected] = useState(true); // Toggle for Specials

  // Time filters for Events and Specials
  const [eventsTimeFilter, setEventsTimeFilter] = useState('');
  const [specialsTimeFilter, setSpecialsTimeFilter] = useState('');

  // Category filters for Events and Specials
  const [eventsCategoryFilter, setEventsCategoryFilter] = useState('');
  const [specialsCategoryFilter, setSpecialsCategoryFilter] = useState('');

  // Filter bar state and reference
  const [filterBarOpen, setFilterBarOpen] = useState(false); // Toggle for filter bar visibility
  const filterBarRef = useRef(null); // Reference to the filter bar for ClickAwayListener

  // User follow and recenter states
  const [shouldFollowUser, setShouldFollowUser] = useState(false); // Toggle to follow user location
  const [isInitialLocation, setIsInitialLocation] = useState(true); // Flag for initial location setting

  // Theme and media query hooks for responsive design
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Reference to the map container for dynamic height adjustment
  const mapContainerRef = useRef(null);

  // Function to dynamically set the map container's height
  const setMapContainerHeight = useCallback(() => {
    if (mapContainerRef.current) {
      const windowHeight = window.innerHeight;
      const appBarHeight = document.getElementById('app-bar').offsetHeight;
      const bottomNavHeight = document.getElementById('bottom-nav').offsetHeight;
      const availableHeight = windowHeight - appBarHeight - bottomNavHeight;
      mapContainerRef.current.style.height = `${availableHeight}px`;
    }
  }, []);

  // Handle filter toggle
  const handleFilterToggle = () => {
    setFilterPanelsVisible(!filterPanelsVisible);
    setFilterBarOpen(false); // Close the old filter bar if it was open
  };

  // Handle clearing filters
  const handleClearFilters = () => {
    setEventsSelected(true);
    setSpecialsSelected(true);
    setEventsTimeFilter('');
    setSpecialsTimeFilter('');
    setEventsCategoryFilter('');
    setSpecialsCategoryFilter('');
  };

  // Effect to set the map container height on mount and window resize
  useEffect(() => {
    if (view === 'map') {
      // Only set the height if the current view is 'map'
      setMapContainerHeight();
      window.addEventListener('resize', setMapContainerHeight);
      return () => window.removeEventListener('resize', setMapContainerHeight);
    }
  }, [view, setMapContainerHeight]);

  // Handle back navigation from non-map views
  const handleBack = () => {
    if (view !== 'map') {
      setView('map');
      setCurrentView('map'); // Ensure currentView is updated
    }
  };

  // Adjust body overflow based on currentView
  useEffect(() => {
    if (currentView === 'map') {
      document.body.style.overflow = 'hidden'; // Disable scrolling on the map view
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling on other views
    }
    return () => {
      document.body.style.overflow = 'auto'; // Cleanup on unmount
    };
  }, [currentView]);

  // Fetch markers from the backend when the component mounts
  useEffect(() => {
    const fetchMarkers = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/events`);
        const data = await response.json();
        const eventsWithStatus = data.map((event) => ({
          ...event,
          status: getMarkerStatus(event),
        }));
        setMarkers(eventsWithStatus);
      } catch (error) {
        console.error('Error fetching markers:', error);
      }
    };
    fetchMarkers();
  }, []);

  // Watch the user's location and update the map accordingly
  useEffect(() => {
    let watchId;
    if (navigator.geolocation) {
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          const newUserLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setUserLocation(newUserLocation);

          // Center the map on the user's location when it's first obtained
          if (isInitialLocation) {
            setViewState((prevState) => ({
              ...prevState,
              latitude: newUserLocation.latitude,
              longitude: newUserLocation.longitude,
              zoom: 12, // Adjust zoom level as needed
            }));
            setIsInitialLocation(false);
          } else if (shouldFollowUser) {
            // Update the map view only if shouldFollowUser is true
            setViewState((prevState) => ({
              ...prevState,
              latitude: newUserLocation.latitude,
              longitude: newUserLocation.longitude,
              // Keep the current zoom level
              zoom: prevState.zoom,
            }));
          }
        },
        (error) => {
          console.error('Error getting user location:', error);
        },
        {
          enableHighAccuracy: true,
          timeout: 20000,
          maximumAge: 1000,
          distanceFilter: 5,
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [shouldFollowUser, isInitialLocation]);

  // Function to handle manual recentering of the map
  const handleRecenterMap = () => {
    if (userLocation) {
      setViewState((prevState) => ({
        ...prevState,
        latitude: userLocation.latitude,
        longitude: userLocation.longitude,
        zoom: 12, // Adjust zoom level
        transitionDuration: 1000, // Smooth transition
      }));
    }
  };

  // Hide the map alert after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setMapAlertVisible(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  // Effect to reset filters when returning to the map view
  useEffect(() => {
    if (view === 'map') {
      // Reset the time and category filters to their default states
      setEventsTimeFilter('');
      setSpecialsTimeFilter('');
      setEventsCategoryFilter('');
      setSpecialsCategoryFilter('');
    }
  }, [view]);

  // Compute categories for Events and Specials
  const [eventsCategories, specialsCategories] = useMemo(() => {
    const eventsCategoriesSet = new Set();
    const specialsCategoriesSet = new Set();
    markers.forEach((marker) => {
      if (marker.category) {
        if (marker.type.toLowerCase() === 'event') {
          eventsCategoriesSet.add(marker.category);
        } else if (marker.type.toLowerCase() === 'special') {
          specialsCategoriesSet.add(marker.category);
        }
      }
    });
    return [Array.from(eventsCategoriesSet), Array.from(specialsCategoriesSet)];
  }, [markers]);

  // Compute filtered markers based on selected filters
  const filteredMarkers = useMemo(() => {
    return markers.filter((marker) => {
      const isEvent = marker.type.toLowerCase() === 'event';
      const isSpecial = marker.type.toLowerCase() === 'special';

      // Filter by Events and Specials toggle
      if (!eventsSelected && isEvent) return false;
      if (!specialsSelected && isSpecial) return false;

      const markerStatus = getMarkerStatus(marker);

      // Exclude events and specials that have ended or are more than 7 days in the future
      if (['green', 'yellow', 'grey'].includes(markerStatus) === false) {
        return false;
      }

      // Apply time filters for Events
      if (isEvent && eventsTimeFilter) {
        if (eventsTimeFilter === 'Now' && markerStatus !== 'green') return false;
        if (eventsTimeFilter === 'Today' && !['green', 'yellow'].includes(markerStatus)) {
          return false;
        }
        if (eventsTimeFilter === 'Upcoming' && markerStatus !== 'grey') return false;
      }

      // Apply time filters for Specials
      if (isSpecial && specialsTimeFilter) {
        if (specialsTimeFilter === 'Now' && markerStatus !== 'green') return false;
        if (specialsTimeFilter === 'Today' && !['green', 'yellow'].includes(markerStatus)) {
          return false;
        }
        if (specialsTimeFilter === 'Upcoming' && markerStatus !== 'grey') return false;
      }

      // Apply category filters for Events
      if (isEvent && eventsCategoryFilter && marker.category !== eventsCategoryFilter) {
        return false;
      }

      // Apply category filters for Specials
      if (isSpecial && specialsCategoryFilter && marker.category !== specialsCategoryFilter) {
        return false;
      }

      return true;
    });
  }, [
    markers,
    eventsSelected,
    specialsSelected,
    eventsTimeFilter,
    specialsTimeFilter,
    eventsCategoryFilter,
    specialsCategoryFilter,
  ]);

// Points preparation for clustering in App.js
  const points = useMemo(() => {
    // Helper function to get status priority for sorting
    const getStatusPriority = (status) => {
      switch (status) {
        case 'green':
          return 2;
        case 'yellow':
          return 1;
        case 'grey':
          return 0;
        default:
          return -1;
      }
    };

    // Helper function to create a consistent location key from venue and address
    const createLocationKey = (marker) => {
      try {
        // Get the street number and name
        const addressParts = marker.address.split(',');
        const street = addressParts[0].trim();  // "96 Kensington Rd"

        // Get the city - it's typically the second part, before the province
        const city = addressParts[1].trim().split(' ')[0];  // "Charlottetown"

        // Combine venue, street, and city into a consistent key
        // Convert to lowercase and remove extra spaces for consistency
        const locationKey = `${marker.venue}_${street}_${city}`.toLowerCase().replace(/\s+/g, ' ');

        return locationKey;
      } catch (error) {
        // If there's any error parsing the address, fall back to using coordinates
        // This ensures we don't crash if address format is unexpected
        console.warn(`Address parsing failed for venue ${marker.venue}, falling back to coordinates`, error);
        return `${marker.latitude},${marker.longitude}`;
      }
    };

    // Group markers by location key
    const groupedMarkers = filteredMarkers.reduce((acc, marker) => {
      const locationKey = createLocationKey(marker);
      
      // Initialize array for this location if it doesn't exist
      if (!acc[locationKey]) {
        acc[locationKey] = [];
      }

      // Add marker to the appropriate location group
      acc[locationKey].push(marker);
      return acc;
    }, {});

    // Convert grouped markers into features for supercluster
    return Object.entries(groupedMarkers).map(([locationKey, locationMarkers]) => {
      // Find the marker with highest priority status at this location
      const highestPriorityMarker = locationMarkers.reduce((highest, current) => {
        return getStatusPriority(current.status) > getStatusPriority(highest.status)
          ? current
          : highest;
      });

      // Use the first marker's coordinates for the group
      // This ensures consistent positioning for all events at this location
      const firstMarker = locationMarkers[0];
      
      return {
        type: 'Feature',
        properties: {
          cluster: false,
          markerId: highestPriorityMarker.id,
          category: highestPriorityMarker.category,
          type: highestPriorityMarker.type,
          status: highestPriorityMarker.status,
          eventCount: locationMarkers.length,
          markers: locationMarkers,
          venue: firstMarker.venue,
          address: firstMarker.address
        },
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(firstMarker.longitude),
            parseFloat(firstMarker.latitude),
          ],
        },
      };
    });
  }, [filteredMarkers]);

  // Use Supercluster for clustering markers
  const { clusters, supercluster } = useSupercluster({
    points,
    zoom: viewState.zoom,
    bounds: bounds,
    options: {
      radius: 75,
      maxZoom: 20,
      minPoints: 2,
    },
  });

  // Handle map load to set initial bounds
  const handleMapLoad = useCallback((event) => {
    const map = event.target;
    const updateBounds = () => {
      const b = map.getBounds();
      setBounds([b.getWest(), b.getSouth(), b.getEast(), b.getNorth()]);
    };
    map.on('moveend', updateBounds);
    updateBounds();
  }, []);

  // Handle cluster click to zoom into clusters
  const handleClusterClick = useCallback(
    (clusterId, longitude, latitude) => {
      const children = supercluster.getLeaves(clusterId, Infinity);
      if (children.length === 1 && children[0].properties.eventCount > 1) {
        setSelectedLocation(children[0].properties.markers);
        setSelectedCluster(null);
      } else {
        setSelectedCluster(children.map((child) => child.properties.markers).flat());
        setSelectedLocation(null);
      }
      setViewState({
        ...viewState,
        longitude,
        latitude,
        transitionDuration: 500,
      });
    },
    [supercluster, setViewState, viewState]
  );

  // Handle toggling Events
  const handleEventsToggle = () => {
    setEventsSelected(!eventsSelected);
  };

  // Handle click away from filter bar to close it
  const handleClickAway = (event) => {
    if (filterBarRef.current && !filterBarRef.current.contains(event.target)) {
      setFilterBarOpen(false);
    }
  };

  // Handle event click (placeholder for further logic)
  const handleEventClick = (event) => {
    console.log('Event clicked:', event);
    // Add additional logic here, such as navigating to an event detail page
  };

  // Handle map click to close popup
  const handleMapClick = () => {
    if (selectedLocation) {
      setSelectedLocation(null);
      setSelectedCluster(null);
    }
  };

  // Handle map drag to close popup
  const handleMapDrag = () => {
    if (selectedLocation) {
      setSelectedLocation(null);
      setSelectedCluster(null);
    }
  };

  // Main render function
  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden' }}>
      {/* AppBar at the top */}
      <AppBar id="app-bar" position="fixed" sx={{ top: 0, zIndex: 1200 }}>
        <Toolbar sx={{ minHeight: '56px' }}>
          <Box sx={{ width: 48, display: 'flex', justifyContent: 'center' }}>
            {view !== 'map' && (
              <IconButton edge="start" color="inherit" onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            )}
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <img
              src={`${process.env.PUBLIC_URL}/logo192.webp`}
              alt="GathR Logo"
              style={{ height: 40 }}
            />
          </Box>
          <Box sx={{ width: 48, display: 'flex', justifyContent: 'center' }}>
            <IconButton color="inherit" aria-label="settings">
              <SettingsIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Bottom Navigation */}
      <BottomNavigation
        id="bottom-nav"
        value={view}
        onChange={(event, newValue) => {
          setView(newValue);
          setCurrentView(newValue); // Ensure currentView is updated
        }}
        showLabels
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1200,
          height: '56px',
          boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
          borderTop: '1px solid #ddd',
        }}
      >
        <BottomNavigationAction label="Events" icon={<EventIcon />} value="events" />
        <BottomNavigationAction label="Map" icon={<LocationOnIcon />} value="map" />
        <BottomNavigationAction label="Specials" icon={<SpecialsIcon />} value="specials" />
      </BottomNavigation>

      {/* Main content area */}
      <Box
        sx={{
          position: 'absolute',
          top: `${document.getElementById('app-bar')?.offsetHeight || 56}px`,
          bottom: `${document.getElementById('bottom-nav')?.offsetHeight || 56}px`,
          left: 0,
          right: 0,
          overflow: 'hidden',
        }}
      >
        {view === 'map' && (
          <Box
            ref={mapContainerRef}
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            {/* Map component */}
            <Map
              ref={mapRef}
              {...viewState}
              onMove={(evt) => setViewState(evt.viewState)}
              style={{ width: '100%', height: '100%' }}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              mapboxAccessToken={MAPBOX_TOKEN}
              onClick={handleMapClick}
              onDrag={handleMapDrag}
              onLoad={handleMapLoad}
              // Disable interactions when popup is open
              dragPan={!selectedLocation}
              dragRotate={!selectedLocation}
              scrollZoom={!selectedLocation}
              touchZoomRotate={!selectedLocation}
              keyboard={!selectedLocation}
            >
              {/* User location marker */}
              {userLocation && (
                <Marker
                  latitude={userLocation.latitude}
                  longitude={userLocation.longitude}
                  anchor="center"
                >
                  <div
                    style={{
                      position: 'relative',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    {/* User location icon */}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="#E53935"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 0C7.31 0 3.5 3.81 3.5 8.5C3.5 14.88 12 24 12 24C12 24 20.5 14.88 20.5 8.5C20.5 3.81 16.69 0 12 0ZM12 11.5C10.34 11.5 9 10.16 9 8.5C9 6.84 10.34 5.5 12 5.5C13.66 5.5 15 6.84 15 8.5C15 10.16 13.66 11.5 12 11.5Z" />
                    </svg>
                    {/* Pulsing animation */}
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        animation: 'pulse 2s infinite',
                      }}
                    ></div>
                    <style jsx>{`
                      @keyframes pulse {
                        0% {
                          box-shadow: 0 0 0 0 rgba(229, 57, 53, 0.4);
                        }
                        70% {
                          box-shadow: 0 0 0 15px rgba(229, 57, 53, 0);
                        }
                        100% {
                          box-shadow: 0 0 0 0 rgba(229, 57, 53, 0);
                        }
                      }
                    `}</style>
                  </div>
                </Marker>
              )}

              {/* Render clusters and markers */}
              {clusters.map((cluster) => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const { cluster: isCluster, point_count: pointCount } = cluster.properties;

                if (isCluster) {
                  // Render cluster marker
                  return (
                    <Marker key={`cluster-${cluster.id}`} latitude={latitude} longitude={longitude}>
                      <div
                        style={{
                          width: `${20 + (pointCount / points.length) * 20}px`,
                          height: `${20 + (pointCount / points.length) * 20}px`,
                          borderRadius: '50%',
                          backgroundColor: '#1976d2',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: 12,
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          if (!selectedLocation) {
                            e.stopPropagation();
                            handleClusterClick(cluster.id, longitude, latitude);
                          }
                        }}
                      >
                        {pointCount}
                      </div>
                    </Marker>
                  );
                }

                const marker = cluster.properties;
                return (
                  <Marker
                    key={`marker-${marker.markerId}`}
                    latitude={latitude}
                    longitude={longitude}
                    anchor="bottom"
                    onClick={(e) => {
                      if (!selectedLocation) {
                        e.originalEvent.stopPropagation();
                        setSelectedLocation(marker.markers);
                        setSelectedCluster(null);
                        setViewState((prevState) => ({
                          ...prevState,
                          longitude: longitude,
                          latitude: latitude + 0.0002,
                          transitionDuration: 500,
                        }));
                      }
                    }}
                  >
                    <CustomMarker
                      type={marker.type}
                      status={marker.status}
                      count={marker.eventCount > 1 ? marker.eventCount : undefined}
                    />
                  </Marker>
                );
              })}

              {/* Render selected location popup */}
              {selectedLocation && (
                <CarouselPopup
                  markers={selectedLocation}
                  onClose={() => setSelectedLocation(null)}
                  mapRef={mapRef}
                />
              )}

              {/* Render selected cluster carousel */}
              {selectedCluster && (
                <ClusterCarousel
                  events={selectedCluster}
                  onClose={() => setSelectedCluster(null)}
                />
              )}
            </Map>

            {/* Filter panel */}
            <Box
              sx={{
                position: 'absolute',
                top: 8,
                left: 10,
                right: 10,
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <FilterPanel
                // Events states
                eventsSelected={eventsSelected}
                setEventsSelected={setEventsSelected}
                eventsTimeFilter={eventsTimeFilter}
                setEventsTimeFilter={setEventsTimeFilter}
                eventsCategoryFilter={eventsCategoryFilter}
                setEventsCategoryFilter={setEventsCategoryFilter}
                eventsCategories={eventsCategories}
                // Specials states
                specialsSelected={specialsSelected}
                setSpecialsSelected={setSpecialsSelected}
                specialsTimeFilter={specialsTimeFilter}
                setSpecialsTimeFilter={setSpecialsTimeFilter}
                specialsCategoryFilter={specialsCategoryFilter}
                setSpecialsCategoryFilter={setSpecialsCategoryFilter}
                specialsCategories={specialsCategories}
                // Visibility control
                isVisible={filterPanelsVisible}
                onClose={handleFilterToggle}
                onClear={handleClearFilters}
              />
            </Box>

            {/* Recenter and Follow User Buttons */}
            <Box
              sx={{
                position: 'absolute',
                bottom: 110, // Adjusted to be above the BottomNavigation
                right: 16,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: 2,
              }}
            >
              {/* Follow User Button */}
              <Box
                sx={{
                  width: 90,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    mb: 0.25,
                    backgroundColor: '#E9EBEC',
                    px: 0.5,
                    borderRadius: 1,
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '0.6rem',
                  }}
                >
                  {shouldFollowUser ? 'Centered' : 'Stay Centered'}
                </Typography>
                <IconButton
                  onClick={() => setShouldFollowUser(!shouldFollowUser)}
                  sx={{
                    backgroundColor: shouldFollowUser ? 'primary.main' : 'background.paper',
                    color: shouldFollowUser ? 'background.paper' : 'text.primary',
                    '&:hover': {
                      backgroundColor: shouldFollowUser ? 'primary.dark' : 'background.default',
                    },
                    padding: '4px',
                  }}
                >
                  <MyLocationIcon sx={{ fontSize: 16 }} />
                </IconButton>
              </Box>

              {/* Recenter Button */}
              <Box
                sx={{
                  width: 90,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    mb: 0.25,
                    backgroundColor: '#E9EBEC',
                    px: 0.5,
                    borderRadius: 1,
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '0.6rem',
                  }}
                >
                  Recenter
                </Typography>
                <IconButton
                  onClick={handleRecenterMap}
                  sx={{
                    backgroundColor: 'background.paper',
                    color: 'text.primary',
                    '&:hover': {
                      backgroundColor: 'background.default',
                    },
                    padding: '4px',
                  }}
                >
                  <GpsFixedIcon sx={{ fontSize: 16 }} />
                </IconButton>
              </Box>
            </Box>

            {/* Map alert for user location */}
            {isMapAlertVisible && (
              <Box sx={{ position: 'absolute', top: 5, left: 16, zIndex: 2000 }}>
                <Alert severity="info">Map centered on user's location</Alert>
              </Box>
            )}
          </Box>
        )}

        {/* List views for Events and Specials */}
        {(view === 'events' || view === 'specials') && (
          <ListView
            items={markers.filter((marker) =>
              view === 'events'
                ? marker.type.toLowerCase() === 'event'
                : marker.type.toLowerCase() === 'special'
            )}
            onItemClick={handleEventClick}
            viewType={view}
            timeFilter={view === 'events' ? eventsTimeFilter : specialsTimeFilter}
            setTimeFilter={view === 'events' ? setEventsTimeFilter : setSpecialsTimeFilter}
            categoryFilter={view === 'events' ? eventsCategoryFilter : specialsCategoryFilter}
            setCategoryFilter={
              view === 'events' ? setEventsCategoryFilter : setSpecialsCategoryFilter
            }
            searchQuery={view === 'events' ? eventsSearchQuery : specialsSearchQuery}
            setSearchQuery={view === 'events' ? setEventsSearchQuery : setSpecialsSearchQuery}
            userLocation={userLocation}
          />
        )}
      </Box>
    </Box>
  );
}

export default App;