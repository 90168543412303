import React from 'react';
import { styled } from '@mui/material/styles';
import { Select, MenuItem, FormControl, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Wrapper for the entire select component
const SelectWrapper = styled(motion.div)(({ theme }) => ({
  marginBottom: '4px', // Space between components
  width: '100%',
}));

// Label styling
const Label = styled(Typography)(({ theme, disabled }) => ({
  fontSize: '12px',           // Label text size
  fontWeight: 500,           // Label weight
  color: disabled ? theme.palette.text.disabled : theme.palette.text.secondary,
  marginBottom: '2px',       // Space between label and select
}));

// Styled select input
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',     // Select box corner rounding
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    fontSize: '11px',        // Text size in select box
    height: '26px',          // Height of select box
    
    // Border styling
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.2)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
    
    // Dropdown arrow styling
    '& .MuiSelect-icon': {
      width: '14px',         // Arrow icon size
      height: '14px',
      right: '2px',          // Arrow position from right
    },
  },
  
  // Text padding inside select box
  '& .MuiSelect-select': {
    padding: '2px 4px',      // Reduced horizontal padding to fit more text
  },
}));

// Dropdown menu items
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '11px',          // Dropdown text size
  minHeight: '26px',         // Dropdown item height
  padding: '2px 4px',        // Dropdown item padding
}));

// Animation configuration
const containerVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 30
    }
  }
};

const FilterSelect = ({
  label,
  value,
  onChange,
  options,
  disabled = false,
  placeholder = "All",      // Changed default placeholder to just "All"
  style = {},
  labelStyle = {},
  selectStyle = {},
}) => {
  const handleChange = (event) => {
    event.stopPropagation();
    onChange(event);
  };

  return (
    <SelectWrapper
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      style={style}
      onClick={(e) => e.stopPropagation()}
    >
      {label && (
        <Label 
          disabled={disabled}
          style={labelStyle}
        >
          {label}
        </Label>
      )}
      <StyledFormControl disabled={disabled}>
        <Select
          value={value}
          onChange={handleChange}
          displayEmpty
          IconComponent={KeyboardArrowDownIcon}
          onClick={(e) => e.stopPropagation()}
          onOpen={(e) => e.stopPropagation()}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,         // Maximum height of dropdown
                borderRadius: '8px',    // Dropdown corner rounding
                marginTop: '2px',       // Space between select and dropdown
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
              },
              onClick: (e) => e.stopPropagation(),
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          style={selectStyle}
        >
          <MenuItem value="" onClick={(e) => e.stopPropagation()}>
            <em>{placeholder}</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem 
              key={option.value} 
              value={option.value}
              onClick={(e) => e.stopPropagation()}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </SelectWrapper>
  );
};

export default FilterSelect;