import React, { useState, useRef, useEffect } from 'react';
import { Popup } from 'react-map-gl';
import { Box, Typography, Button, IconButton, Card, CardContent, Modal } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const CarouselPopup = ({ markers, onClose, isClusterView = false, initialIndex = 0, onIndexChange, mapRef, isPreview = false }) => {

const POPUP_OFFSET = -55;  // Offset for the pointer
const POPUP_PADDING = 50;  // Padding from screen edges

  /**
 * CarouselPopup Component
 * Displays event/special information in a popup card with carousel functionality
 * 
 * @param {Object[]} markers - Array of marker data to display
 * @param {Function} onClose - Callback function to close the popup
 * @param {boolean} isClusterView - Whether popup is being used in cluster view
 * @param {number} initialIndex - Initial index for carousel
 * @param {Function} onIndexChange - Callback for index changes
 */

  // State Management
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [popupCoordinates, setPopupCoordinates] = useState(null);

  // Refs
  const descriptionRef = useRef(null);
  const popupRef = useRef(null);

  // Sort markers by date
  const sortedMarkers = [...markers].sort((a, b) => {
    const dateA = moment(`${a.startDate} ${a.startTime}`, 'YYYY-MM-DD HH:mm:ss');
    const dateB = moment(`${b.startDate} ${b.startTime}`, 'YYYY-MM-DD HH:mm:ss');
    return dateA - dateB;
  });

  const currentMarker = sortedMarkers[currentIndex] || sortedMarkers[0];

  useEffect(() => {
    if (currentMarker && mapRef?.current) {
      const map = mapRef.current;
      const markerLng = Number(currentMarker.longitude);
      const markerLat = Number(currentMarker.latitude);
  
      // Get map dimensions
      const mapWidth = map.getContainer().clientWidth;
      const mapHeight = map.getContainer().clientHeight;
  
      // Estimate popup height (you might need to adjust this)
      const popupHeight = 280; // Adjust this value based on your popup's actual height
  
      // Convert marker coordinates to screen coordinates
      const markerScreenPosition = map.project([markerLng, markerLat]);
  
      // Calculate the offset in pixels
      const offsetY = popupHeight / 2;
  
      // Calculate the new screen position
      const targetScreenPosition = {
        x: markerScreenPosition.x,
        y: markerScreenPosition.y - offsetY,
      };
  
      // Convert back to geographical coordinates
      const targetCenter = map.unproject(targetScreenPosition);
  
      // Move map to center on this position
      map.easeTo({
        center: [targetCenter.lng, targetCenter.lat],
        duration: 500,
      });
  
      setPopupCoordinates({
        latitude: markerLat,
        longitude: markerLng,
      });
    }
  }, [currentMarker, mapRef]);
  

  // Reset state when markers change
  useEffect(() => {
    setCurrentIndex(0);
    setCurrentImageIndex(0);
    setExpanded(false);
  }, [markers]);

  // Check for text truncation
  useEffect(() => {
    if (descriptionRef.current) {
      setIsTextTruncated(
        descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight
      );
    }
  }, [currentMarker?.description]);

  /**
   * Collect all available images for a marker
   */
  const getImages = (marker) => {
    if (!marker) return [];
    const images = [];
    if (marker.relevantImageUrl) images.push(marker.relevantImageUrl);
    if (marker.imageUrl) images.push(marker.imageUrl);
    if (marker.SharedPostThumbnail) images.push(marker.SharedPostThumbnail);
    return images;
  };




  const images = getImages(currentMarker);

  /**
   * Image carousel navigation handlers
   */
  const handleNextImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  /**
   * Marker carousel navigation handlers
   */
  const handleNext = (e) => {
    e.stopPropagation();
    const newIndex = (currentIndex + 1) % sortedMarkers.length;
    setCurrentIndex(newIndex);
    setCurrentImageIndex(0);
    setExpanded(false);
    if (typeof onIndexChange === 'function') {
      onIndexChange(newIndex);
    }
  };

  const handlePrev = (e) => {
    e.stopPropagation();
    const newIndex = (currentIndex - 1 + sortedMarkers.length) % sortedMarkers.length;
    setCurrentIndex(newIndex);
    setCurrentImageIndex(0);
    setExpanded(false);
    if (typeof onIndexChange === 'function') {
      onIndexChange(newIndex);
    }
  };

  /**
   * Touch event handlers for swipe functionality
   */
  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe) {
      handleNext({ stopPropagation: () => {} });
    } else if (isRightSwipe) {
      handlePrev({ stopPropagation: () => {} });
    }
  };

  /**
   * Date and time formatting utilities
   */
  const formatDate = (date, includeYear = false) => {
    return moment(date).format(includeYear ? 'MMM D, YYYY' : 'MMM D');
  };

  const formatTime = (timeString) => moment(timeString, ['h:mm A', 'H:mm:ss']).format('h:mm A');

  const getTimeDisplay = (startDate, startTime, endDate, endTime, status) => {
    console.log('Input values:', { startDate, startTime, endDate, endTime, status });
    
    const now = moment();
    const start = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    const end = moment(`${endDate} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');
    const isEndNextYear = end.year() > start.year();
  
    console.log('Parsed dates:', {
      start: start.format('YYYY-MM-DD HH:mm:ss'),
      end: end.format('YYYY-MM-DD HH:mm:ss'),
      isEndNextYear
    });
  
    if (status === 'green' || now.isBetween(start, end)) {
      const formattedEndTime = formatTime(endTime);
      const formattedEndDate = formatDate(endDate, isEndNextYear);
      const result = `Happening Now until ${formattedEndTime} ${formattedEndDate}`;
      console.log('Formatted result:', result);
      return result;
    }
  
    if (moment(startDate).isSame(moment(), 'day')) {
      return `Today, ${formatTime(startTime)} - ${formatTime(endTime)}`;
    }
  
    if (isEndNextYear) {
      return `${formatDate(startDate)} ${formatTime(startTime)} - ${formatDate(endDate, true)} ${formatTime(endTime)}`;
    }
  
    return `${formatDate(startDate)} ${formatTime(startTime)} - ${formatTime(endTime)}`;
  };

  const formatAddress = (address) => {
    const parts = address.split(',');
    return parts[0].trim();
  };

  /**
   * Ticket link handling
   */
  const isValidTicketLink = (link) => {
    return link && link !== '' && link.toLowerCase() !== 'n/a';
  };

  const handleGetTickets = () => {
    const ticketLink = isValidTicketLink(currentMarker.ticketLinkPosts) 
      ? currentMarker.ticketLinkPosts 
      : currentMarker.ticketLinkEvents;
    
    if (isValidTicketLink(ticketLink)) {
      window.open(ticketLink, '_blank');
    }
  };

  const handleExpandClick = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
    if (!expanded && descriptionRef.current) {
      // Reset scroll position when collapsing
      descriptionRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (!expanded && descriptionRef.current) {
      // Ensure the scroll position resets to the top when collapsing
      descriptionRef.current.scrollTo(0, 0);
    }
  }, [expanded]);

  /**
   * Image fullscreen handling
   */
  const handleImageClick = (e) => {
    if (isPreview) {
      // Do not open full-screen mode
      // Allow the event to propagate to the parent
      // Ensure we do not stop event propagation
    } else {
      // Existing code to open image in full-screen mode
      e.stopPropagation(); // Prevent the click event from propagating if not in preview
      setIsFullScreen(true);
    }
  };

  const handleCloseFullScreen = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating
    setIsFullScreen(false);
  };
  

  // Main card content
  // Main card content
  const content = (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center all children horizontally
      }}
    >
      <Card 
        sx={{ 
          width: 315, 
          maxHeight: 280, 
          overflow: 'hidden', 
          display: 'flex', 
          flexDirection: 'column', 
          boxShadow: 3,
          position: 'relative',
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Venue Header */}
        <Box sx={{ bgcolor: '#2196f3', color: 'white', p: 0.7 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {currentMarker.profileUrl && (
                <img 
                  src={currentMarker.profileUrl}
                  alt={currentMarker.venue}
                  style={{ width: '17px', height: '17px', marginRight: '6px', borderRadius: '50%' }}
                />
              )}
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '0.7rem' }}>
                {currentMarker.venue}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ fontSize: '0.5rem', textAlign: 'center' }}>
              {formatAddress(currentMarker.address)}
            </Typography>
          </Box>
        </Box>
  
        {/* Content Area */}
        <Box sx={{ display: 'flex', height: 112 }}>
          {/* Image Section */}
          <Box sx={{ position: 'relative', width: '50%' }}>
            <img
              src={images[currentImageIndex]}
              alt={currentMarker.title}
              style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
              onClick={handleImageClick}
            />
          </Box>
          {/* Description Section */}
          <Box sx={{ width: '50%', p: 0.7, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1" color="text.primary" sx={{ fontSize: '0.7rem', fontWeight: 'bold', mb: 0.7 }}>
              {currentMarker.title}
            </Typography>
            <Typography
              ref={descriptionRef}
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: '0.56rem',
                flexGrow: 1,
                overflow: expanded ? 'auto' : 'hidden',  // Change overflow when expanded
                maxHeight: expanded ? '150px' : '3.5em', // Set max height for expanded view
                position: 'relative',
                '&::after': (!expanded && isTextTruncated) ? {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  width: '100%',
                  height: '1.05em',
                  background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
                } : {}
              }}
            >
              {currentMarker.description}
            </Typography>

            {isTextTruncated && (
              <Typography
                component="a"
                href="#"
                color="#2196f3"
                onClick={handleExpandClick} // Use the new function here
                sx={{ fontSize: '0.56rem', textDecoration: 'none', '&:hover': { textDecoration: 'underline' }, mt: 0.35 }}
              >
                {expanded ? 'READ LESS' : 'READ MORE'}
              </Typography>
            )}
          </Box>
        </Box>
  
        {/* Footer Section */}
        <CardContent sx={{ p: 0.7 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body2" color="#2196f3" sx={{ fontSize: '0.56rem', fontWeight: 'bold' }}>
              {getTimeDisplay(currentMarker.startDate, currentMarker.startTime, currentMarker.endDate, currentMarker.endTime, currentMarker.status)}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.56rem', mr: 0.7 }}>
                Price: {currentMarker.ticketPrice || 'Free'}
              </Typography>
              {(isValidTicketLink(currentMarker.ticketLinkPosts) || isValidTicketLink(currentMarker.ticketLinkEvents)) && (
                <Button 
                  size="small" 
                  onClick={handleGetTickets}
                  sx={{ 
                    fontSize: '0.56rem', 
                    bgcolor: '#2196f3', 
                    color: 'white', 
                    '&:hover': { bgcolor: '#1976d2' },
                    padding: '1px 4px',
                    minWidth: '50px'
                  }}
                >
                  GET TICKETS
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
  
        {/* Navigation Section */}
        <Box sx={{ 
          p: 0.7, 
          borderTop: '1px solid rgba(0,0,0,0.12)', 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: 'rgba(33, 150, 243, 0.1)'
        }}>
          <IconButton size="small" onClick={handlePrev} sx={{ padding: '1px' }}>
            <ArrowBackIosNewIcon sx={{ fontSize: '0.7rem' }} />
          </IconButton>
          <Box sx={{ display: 'flex', mx: 1 }}>
            {sortedMarkers.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  mx: 0.3,
                  bgcolor: index === currentIndex ? '#2196f3' : 'rgba(0,0,0,0.3)',
                }}
              />
            ))}
          </Box>
          <IconButton size="small" onClick={handleNext} sx={{ padding: '2px' }}>
            <ArrowForwardIosIcon sx={{ fontSize: '0.7rem' }} />
          </IconButton>
        </Box>
      </Card>
      
      {/* Arrow Element - Only render if not in cluster view */}
      {!isClusterView && (
        <Box
          sx={{
            position: 'absolute',
            bottom: -20,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '20px 20px 0 20px',
            borderColor: '#fff transparent transparent transparent',
            filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.1))',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
  

  // Fullscreen modal for images
  const fullScreenModal = (
    <Modal
  open={isFullScreen}
  onClose={handleCloseFullScreen} // This only affects the modal, not the Popup
  aria-labelledby="full-screen-image"
  aria-describedby="full-screen-image-description"
>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    <IconButton
      onClick={handleCloseFullScreen}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: 'white',
        bgcolor: 'rgba(0,0,0,0.5)',
        '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' },
      }}
    >
      <CloseIcon />
    </IconButton>
    <img
      src={images[currentImageIndex]}
      alt={currentMarker.title}
      style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
    />
  </Box>
    </Modal>

  );

  // Render logic based on view type
  if (isClusterView) {
    return (
      <>
        {content}
        {fullScreenModal}
      </>
    );
  }

  return (
    <>
      <Popup
  latitude={popupCoordinates?.latitude || Number(currentMarker.latitude)}
  longitude={popupCoordinates?.longitude || Number(currentMarker.longitude)}
  onClose={onClose} // This is specifically for the main popup, not the modal
  closeOnClick={false}
  anchor="bottom"
  offset={[0, POPUP_OFFSET]}
  className="custom-popup"
  captureClick={true}
  >
  {content}
  <style jsx global>{`
    .custom-popup {
      transition: all 0s ease-in-out; // change this 0.3 to add the "bounce" / hop when moving to a carouselpopup.
    }
    .custom-popup .mapboxgl-popup-content {
      padding: 0;
      background: none;
      border-radius: 5.6px;
      box-shadow: none;
      transform-origin: bottom center;
      animation: popup-appear 0.3s ease-out forwards;
    }
    .custom-popup .mapboxgl-popup-tip {
      display: none;
    }
    .custom-popup .mapboxgl-popup-close-button {
      display: none;
    }
    @keyframes popup-appear {
      from {
        transform: translateY(-20px) scale(0.95);
        opacity: 0;
      }
      to {
        transform: translateY(0) scale(1);
        opacity: 1;
      }
    }
  `}</style>
      </Popup>

      {fullScreenModal}
    </>
  );
};

export default CarouselPopup;